import logo from './logo.svg';
import './App.css';
// import Item from './MyItem';

import React from "react";

class StarWars extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loadedCharacter: false,
      name: null,
      height: null,
      mass: null,
      affiliations: [],
      imageUrl: null,
      users: null,
    }
  }
  getNewCharacter() {
    console.log("Hey from getNewCharacter");
    const randomNumber = Math.floor(Math.random() * 88) + 1;
    // const url = `https://swapi.dev/api/people/${randomNumber}/`; //template literal
    const url = `https://akabab.github.io/starwars-api/api/id/${randomNumber}.json`; //template literal
      this.getUsers();

    fetch(url).then(response => response.json())
        .then(data => {
          console.log(data);
          this.setState({
            loadedCharacter: true,
            name: data.name,
            height: data.height,
            mass: data.mass,
            imageUrl: data.image,
            affiliations: data.affiliations,
          })
        });

  }

  getUsers() {

      console.log("Hey from getUsers");
      const urlUsers = `https://abc-api.whitesmoke-193521e2.australiaeast.azurecontainerapps.io/api/user`;
      // https://abc-backend--3e8z2sx.internal.whitesmoke-193521e2.australiaeast.azurecontainerapps.io/api/user
      fetch(urlUsers).then(response => response.json())
          .then(data => {
              console.log(data);
              this.setState({
                  users: data.fullName
              })
          });
  }
  render() {
    // const movies: FilmItemRow = this.state.films.map((url, i) => {
    //   return <FilmItemRow key={i} url={url} />
    // });

    // const affiliations: FilmItemRow = this.state.affiliations.map((url, i) => {
    //     return <FilmItemRow key={i} url={url} />
    // });

    const affiliations = this.state.affiliations.map((myList)=>{
      return <li>{myList}</li>;
    });

    // const users = this.state.users.map((myList)=>{
    //     return <li>{myList}</li>;
    // });

    return (
        <div>
          {
              this.state.loadedCharacter &&
              <div>
                <h1>{this.state.name}</h1>
                <img src={this.state.imageUrl} alt={this.state.name} width={350} height={400}/>
                <p>Height: {this.state.height} meter</p>
                {/*<p>{this.state.mass} kg</p>*/}
                {/*<p><a href={this.state.homeworld}>Homeworld</a> </p>*/}
                <ul>
                  {affiliations}
                </ul>
                <p>Users: {this.state.users}</p>

              </div>
          }
          <button type="button"
                  onClick={() => this.getNewCharacter() && this.getUsers()}
                  className="btn">
            Randomise Starwars Character
          </button>
        </div>
    );
  }
}

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <StarWars />
        </header>
      </div>
  );
}

export default App;
